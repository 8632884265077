import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ApplicationShell, setupGlobalErrorListener, RouteCatchAll } from "@commercetools-frontend/application-shell";
import { Sdk } from "@commercetools-frontend/sdk";
import * as globalActions from "@commercetools-frontend/actions-global";
import { Helmet } from "react-helmet";
import { FEATURE_FLAGS, entryPointUriPath } from "../../constants/constants";
import loadMessages from "../../load-messages";

// Here we split up the main (app) bundle with the actual application business logic.
// Splitting by route is usually recommended and you can potentially have a splitting
// point for each route. More info at https://reactjs.org/docs/code-splitting.html
const AsyncApplicationRoutes = React.lazy(() => import("../../routes" /* webpackChunkName: "starter-routes" */));

export const ApplicationStarter = () => (
  <Switch>
    {
      /* For development, it's useful to redirect to the actual
      application routes when you open the browser at http://localhost:3001 */
      <Redirect exact from={`/:projectKey/${entryPointUriPath}`} to={`/:projectKey/${entryPointUriPath}/order`} />
    }
    {/* Might need to add /order to the path in order to add it as default url */}
    <Route path={`/:projectKey/${entryPointUriPath}`} component={AsyncApplicationRoutes} />
    {/* Catch-all route */}
    <RouteCatchAll />
  </Switch>
);
ApplicationStarter.displayName = "ApplicationStarter";

// Ensure to setup the global error listener before any React component renders
// in order to catch possible errors on rendering/mounting.
setupGlobalErrorListener();

const EntryPoint = () => (
  <div>
    {" "}
    <Helmet>
      <title>Merchant Center</title>
    </Helmet>
    <ApplicationShell
      environment={window.app}
      onRegisterErrorListeners={({ dispatch }) => {
        Sdk.Get.errorHandler = error => globalActions.handleActionError(error, "sdk")(dispatch);
      }}
      applicationMessages={loadMessages}
      featureFlags={FEATURE_FLAGS}
      render={() => <ApplicationStarter />}
    />
  </div>
);

EntryPoint.displayName = "EntryPoint";

export default EntryPoint;
