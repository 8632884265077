import { entryPointUriPathToPermissionKeys } from "@commercetools-frontend/application-shell/ssr";
import { config } from "../config";

export const FEATURE_FLAGS = {};

export const currency = {
  EUR: "EUR",
  GBP: "GBP",
  DKK: "DKK",
  SEK: "SEK",
  CHF: "CHF"
};

export const processingAmount = {
  [currency.EUR]: 750,
  [currency.GBP]: 750,
  [currency.DKK]: 5500,
  [currency.SEK]: 8000,
  [currency.CHF]: 750
};

// eslint-disable-next-line prefer-destructuring
export const entryPointUriPath = config.entryPointUriPath;

export const PERMISSIONS = entryPointUriPathToPermissionKeys(entryPointUriPath);
